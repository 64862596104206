import $ from "jquery";
import 'svg-pan-zoom-container';

const continent = $('path.sm_state');
const wrapper = $('.world-map__map-wrapper');
const parent = $('.world-map');

// open countries popups functionality
function worldMapClick(){
    
	continent.on('click', function() {
        let pathClass = $(this).attr('class');
        let dataActiveValue = 'on';
        let parentPositionTop = parent.offset().top;
        let positionTop = $(this).offset().top - parentPositionTop;
        let positionLeft = $(this).offset().left;
        let rect = this.getBoundingClientRect();
        let elHeight = rect.height;
        let elWidth = rect.width;

        $('[data-country="' + pathClass + '"]').css({
            'top': positionTop+elHeight + 'px',
            'left': positionLeft+elWidth + 'px',
        });

        $('[data-country].active-state').each(function(){
            const currentDataAttr = $(this).attr('data-country');
            const newDataAttr = currentDataAttr.replace(/\s*active-state\s*/g, '');
            $(this).removeClass('active-state').attr('data-country', newDataAttr);
        })

        $('path.sm_state.active-state').not(this).removeClass('active-state').attr('data-active', '');
        if ($(this).attr('data-active') === dataActiveValue) {
            $(this).removeClass('active-state').attr('data-active', '');
            let pathClass2 = $(this).attr('class');
            $('[data-country="' + pathClass + '"].active-state').removeClass('active-state').attr('data-country', '' + pathClass2 + '');
        } else {
            $('[data-country="' + pathClass + '"]').addClass('active-state').attr('data-country', '' + pathClass + ' active-state');
            $(this).addClass('active-state').attr('data-active', dataActiveValue);
        }

        window.addEventListener('resize', () => {
            $('path.sm_state.active-state').click();
        });
	});

    continent.on('mouseover', function() {
        let pathClass = $(this).attr('class');
        let dataActiveValue = 'on';
        let parentPositionTop = parent.offset().top;
        let positionTop = $(this).offset().top - parentPositionTop;
        let positionLeft = $(this).offset().left;
        let rect = this.getBoundingClientRect();
        let elHeight = rect.height;
        let elWidth = rect.width;
    
        $('[data-country="' + pathClass + '"]').css({
            'top': positionTop+elHeight + 'px',
            'left': positionLeft+elWidth + 'px',
        });
    
        $('[data-country].active-state').each(function(){
            const currentDataAttr = $(this).attr('data-country');
            const newDataAttr = currentDataAttr.replace(/\s*active-state\s*/g, '');
            $(this).removeClass('active-state').attr('data-country', newDataAttr);
        })
    
        $('path.sm_state.active-state').not(this).removeClass('active-state').attr('data-active', '');
        if ($(this).attr('data-active') === dataActiveValue) {
            $(this).removeClass('active-state').attr('data-active', '');
            let pathClass2 = $(this).attr('class');
            $('[data-country="' + pathClass + '"].active-state').removeClass('active-state').attr('data-country', '' + pathClass2 + '');
        } else {
            $('[data-country="' + pathClass + '"]').addClass('active-state').attr('data-country', '' + pathClass + ' active-state');
            $(this).addClass('active-state').attr('data-active', dataActiveValue);
        }
    
        window.addEventListener('resize', () => {
            $('path.sm_state.active-state').click();
        });
    });
}
worldMapClick();

// close all popups if the wrapper is drag
let isMouseDown = false;

wrapper.on('mousedown', function() {
  isMouseDown = true;
});

wrapper.on('mousemove', function() {
  if (isMouseDown) {
    $('path.sm_state.active-state').click();
  }
});

$(document).on('mouseup', function() {
  isMouseDown = false;
});

//Zoom functionality
let zoom = 1;

$('.zoom').on('click', function(){
    zoom += 0.1;
    $('.zoom-target').css('transform', 'scale(' + zoom + ')');
    $('path.sm_state.active-state').click();
});

$('.zoom-init').on('click', function(){
    zoom = 1;
    $('.zoom-target').css('transform', 'scale(' + zoom + ')');
    $('path.sm_state.active-state').click();
});

$('.zoom-out').on('click', function(){
    if (zoom > 1) {
        zoom -= 0.1;
        $('.zoom-target').css('transform', 'scale(' + zoom + ')');
        $('path.sm_state.active-state').click();
    }
});

//add draggable attribute for mobile
function togglePanOnDragAttribute() {
    const windowWidth = $(window).width();

    if (windowWidth < 992) {
        wrapper.attr('data-zoom-on-wheel', '');
        wrapper.attr('data-pan-on-drag', '');
        $(window).on('scroll', function(){
            $('path.sm_state.active-state').click();
        });
    } else {
        wrapper.removeAttr('data-zoom-on-wheel');
        wrapper.removeAttr('data-pan-on-drag');
        $('.zoom-target').css('transform', 'scale(1)');
    }
}

// Initial check on page load
togglePanOnDragAttribute();

// Check on window resize
window.addEventListener('resize', () => {
    togglePanOnDragAttribute();
});

$(document).on('touchstart', function(e) {
    // Get the initial touch position
    var startX = e.originalEvent.touches[0].clientX;
    var startY = e.originalEvent.touches[0].clientY;

    $(document).on('touchmove', function(e) {
        // Calculate the difference in position from the initial touch
        var diffX = e.originalEvent.touches[0].clientX - startX;
        var diffY = e.originalEvent.touches[0].clientY - startY;

        // Check if the user is attempting to pan horizontally or vertically
        if (Math.abs(diffX) > Math.abs(diffY)) {
            // User is panning horizontally
            $('path.sm_state.active-state').click();
        } else {
            // User is panning vertically
            $('path.sm_state.active-state').click();
        }
    });
});

$(document).on('touchend', function() {
    // Remove the touchmove event handler when the touch ends
    $(document).off('touchmove');
});